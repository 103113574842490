<template>
	<phi-page>
		<div slot="toolbar">
			<mu-icon-button @click="$router.go(-1)" icon="arrow_back" />
			<h1>Labs</h1>
		</div>

		<div class="phi-card">
			<router-link to="/geo/feed" class="phi-media">
				<div class="phi-media-figure fa fa-map-marker"></div>
				<div class="phi-media-body">{{ $t('noun.map') }}</div>
			</router-link>
			<router-link to="/geo/targets" class="phi-media">
				<div class="phi-media-figure fa fa-map-marker"></div>
				<div class="phi-media-body">{{ $t('noun.targets') }}</div>
			</router-link>

			<router-link to="/transport/routes" class="phi-media">
				<div class="phi-media-figure fa fa-map-marker"></div>
				<div class="phi-media-body">{{ $t('attendance') }} (transporte)</div>
			</router-link>

			<router-link to="/attendance/admin" class="phi-media">
				<div class="phi-media-figure fa fa-map-marker"></div>
				<div class="phi-media-body">{{ $t('attendance') }} (portales)</div>
			</router-link>
			<router-link to="/leo" class="phi-media">
				<div class="phi-media-figure fa fa-map-marker"></div>
				<div class="phi-media-body">Selector de audiencia</div>
			</router-link>
			<!-- <router-link to="/reko" class="phi-media">
				<div class="phi-media-figure fa fa-map-marker"></div>
				<div class="phi-media-body">Rekognition</div>
			</router-link> -->
			<router-link to="/test" class="phi-media">
				<div class="phi-media-figure fa fa-map-marker"></div>
				<div class="phi-media-body">Test</div>
			</router-link>
			<router-link to="/test/download" class="phi-media">
				<div class="phi-media-figure fa fa-map-marker"></div>
				<div class="phi-media-body">Downloads</div>
			</router-link>
			<router-link to="/test/calendar" class="phi-media">
				<mu-icon class="phi-media-figure" value="today"></mu-icon>
				<div class="phi-media-body">Calendario</div>
			</router-link>
			<router-link to="/test/tucompra" class="phi-media">
				<div class="phi-media-figure fa fa-map-marker"></div>
				<div class="phi-media-body">TuCompra</div>
			</router-link>
			<router-link to="/test/collection" class="phi-media">
				<div class="phi-media-figure fa fa-map-marker"></div>
				<div class="phi-media-body">Collection</div>
			</router-link>
			<router-link to="/test/credits" class="phi-media">
				<div class="phi-media-figure fa fa-map-marker"></div>
				<div class="phi-media-body">Credito</div>
			</router-link>
			<router-link to="/test/popups" class="phi-media">
				<div class="phi-media-figure fa fa-map-marker"></div>
				<div class="phi-media-body">Popups</div>
			</router-link>
			<router-link to="/test/datepicker" class="phi-media">
				<div class="phi-media-figure fa fa-map-marker"></div>
				<div class="phi-media-body">Datepicker</div>
			</router-link>
		</div>

		<br />

		<div class="phi-card">
			<router-link :to="{name: 'container-editor', params:{containerId: 'test', label: 'Blocks'} }" class="phi-media">
				<div class="phi-media-figure fa fa-map-marker"></div>
				<div class="phi-media-body">Blocks</div>
			</router-link>

			<!-- Custom containers -->
			<router-link
				v-for="container in containers"
				:key="container.id"
				:to="{name: 'container-editor', params: {containerId: container.id, label: $t(container.label)}}"
				class="phi-media"
			>
				<mu-icon :value="container.icon" class="phi-media-figure" />
				<span class="phi-media-body">{{ $t(container.label) }}</span>
			</router-link>

		</div>

	</phi-page>
</template>

<script>
export default {
    computed: {
        containers() {
            return this.$store.state.settings && this.$store.state.settings.StateLauncher && this.$store.state.settings.StateLauncher.containers
            ? this.$store.state.settings.StateLauncher.containers
            : [];
		}
	}
}
</script>